<template>
  <!--begin::Row-->
  <div class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div
      class="
        flex-column flex-lg-row-auto
        w-100 w-lg-300px w-xl-800px
        mb-10 mb-lg-0
      "
    >
      <div class="card mb-5 mb-xxl-8 bg-white rounded shadow-sm">
        <div class="card-body">
          <div class="row" style="position: relative">
            <img
              :src="
                isGifPlaying
                  ? '/media/modules/pollster/woman-talking.gif'
                  : '/media/modules/pollster/woman.gif'
              "
              alt="AI"
            />
            <video
              ref="videoElement"
              id="userCamera"
              width="145"
              height="145"
              autoplay
              style="position: absolute; bottom: 0; left: 36%"
            ></video>
          </div>

          <div class="text-center mt-5">
            <button
              v-if="isSurveySessionActive == true"
              @mousedown="startRecording"
              :disabled="isAiActive == false || isLoadingResponse"
              @mouseup="stopRecording"
              :class="{
                'btn btn-warning': !isRecording,
                'btn btn-danger': isRecording,
              }"
              type="button"
              data-kt-element="send"
              style="width: 25%; color: white"
            >
              {{ $t("modals.pollster.pushToTalk") }}
            </button>
            <button
              v-else
              class="btn btn-success"
              type="button"
              data-kt-element="send"
              style="width: 25%; color: white"
              @click="startChat"
            >
              {{ $t("modals.pollster.startPoll") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-5 mb-xxl-8 bg-white rounded shadow-sm">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="d-flex align-items-center flex-column mt-3">
                <div class="d-flex justify-content-center w-100 mt-auto mb-2">
                  <span class="fw-bold fs-5 text-gray-700 me-1"
                    >{{ $t("modals.pollster.percentComplete") }}
                  </span>
                  <span class="fw-bolder fs-5 ms-1">
                    {{ completionPercentage }}%</span
                  >
                </div>

                <div class="h-10px mx-3 w-100 bg-light mb-3">
                  <div
                    class="bg-success rounded h-10px"
                    role="progressbar"
                    :style="{ width: completionPercentage + 1 + '%' }"
                    :aria-valuenow="completionPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <span class="fw-bolder fs-6"
                  >{{ $t("modals.pollster.time") }}: {{ duration }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Sidebar-->

    <!--begin::Messenger-->
    <div
      class="card ms-lg-7 ms-xl-10 bg-white rounded shadow-sm"
      style="min-width: 500px"
      id="kt_chat_messenger"
    >
      <!--begin::Card header-->
      <div class="card-header" id="kt_chat_messenger_header">
        <!--begin::Title-->
        <div class="card-title">
          <!--begin::User-->
          <div class="d-flex justify-content-center flex-column me-3">
            <a
              href="#"
              class="
                fs-4
                fw-bolder
                text-gray-900 text-hover-primary
                me-1
                mb-2
                lh-1
              "
              >{{ $t("modals.pollster.aiPollster") }}</a
            >
            <!--begin::Info-->
            <div class="mb-0 lh-1" v-if="isAiActive">
              <span
                class="badge badge-success badge-circle w-10px h-10px me-1"
              ></span>
              <span class="fs-7 fw-bold text-gray-400">{{
                $t("modals.pollster.active")
              }}</span>
            </div>
            <div class="mb-0 lh-1" v-if="isAiActive != true">
              <span
                class="badge badge-danger badge-circle w-10px h-10px me-1"
              ></span>
              <span class="fs-7 fw-bold text-gray-400">{{
                $t("modals.pollster.deActive")
              }}</span>
            </div>
            <!--end::Info-->
          </div>
          <!--end::User-->
        </div>
        <!--end::Title-->
      </div>
      <!--end::Card header-->

      <!--begin::Card body-->
      <div
        class="card-body"
        id="kt_chat_messenger_body"
        style="padding-bottom: 3; max-width: 500px"
      >
        <!--begin::Messages-->
        <div
          class="scroll-y me-n5 pe-5 h-650px"
          ref="messagesRef"
          data-kt-element="messages"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
          data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
          data-kt-scroll-offset="-2px"
        >
          <template v-for="(item, index) in messages" :key="index">
            <MessageIn
              ref="messagesInRef"
              v-if="item.owner === 'AI'"
              name="Anketör Pelin"
              v-model:text="item.text"
              image="/media/modules/pollster/womantalkingIcon.png"
              :time="item.createdAt"
            ></MessageIn>
            <MessageOut
              ref="messagesOutRef"
              v-if="item.owner === 'USER'"
              :name="item.owner"
              image="/media/avatars/150-3.jpg"
              :time="item.createdAt"
              :text="item.text"
            ></MessageOut>
          </template>
          <div v-if="isLoadingResponse">{{ $t("common.loading") }}</div>
          <div v-else-if="isError">
            <MessageIn
              ref="messagesInRef"
              name="Anketör Pelin"
              text="Bir sorun oluştu. Yönetici ile iletişime geçin."
              image="/media/modules/pollster/womantalkingIcon.png"
              time="Az önce"
            ></MessageIn>
          </div>
        </div>
        <!--end::Messages-->
      </div>
      <!--end::Card body-->

      <!--begin::Card footer-->
      <div
        class="card-footer pt-4 pb-5"
        id="kt_chat_messenger_footer"
        style="padding-bottom: 1"
      >
        <!--begin:Toolbar-->
        <div class="text-center">
          <!--begin::Send-->
          <button
            @click="stopChat"
            :disabled="isAiActive == false || isLoadingResponse"
            class="btn btn-dark"
            type="button"
            data-kt-element="send"
          >
            {{ $t("modals.pollster.endConversation") }}
          </button>
          <!--end::Send-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card footer-->
    </div>
    <!--end::Messenger-->
  </div>
  <!--end::Row-->
</template>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

<script lang="ts">
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { Timer } from "@/presentation/helper/Timer";
import { environment } from "../../../../../../environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { ChatSectionResponseDto } from "@/domain/pollster/model/chatSection/ChatSectionResponseDto";
import { useI18n } from "vue-i18n";
import { getUserDeviceInfo } from "@/presentation/helper/DeviceInfo";
import SocketService from "@/infrastructure/web-socket/SocketService";
import { MessageFlow } from "@/presentation/helper/MessageFlow";
import MediaRecorderService from "@/presentation/helper/MediaRecorder";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { ChatLogResponseAttributes } from "@/domain/pollster/model/chatLog/ChatLogResponseAttributes";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    let token: undefined | string = undefined;
    const surveyId = route.params["id"];
    const isSurveySessionActive = ref(false);

    const socketService = new SocketService();
    const mediaRecorderService = new MediaRecorderService();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const fileController = store.state.ControllersModule.fileController;
    const swalNotification = new SwalNotification();

    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isLoadingResponse = ref(false);
    const isError = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AiSpeakSecond = ref(0);
    let video: any;

    const timer = new Timer();

    let completionPercentage = ref(0);
    const duration = ref("00:00");
    const sessionTime = localStorage.getItem(LOCAL_STORAGE.TIMER);

    const messages = ref<Array<ChatLogResponseAttributes>>([
      {
        owner: "AI",
        text: t("modals.pollster.firstAiMessage"),
        createdAt: new Date().toLocaleTimeString("tr-TR", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);

    onBeforeUnmount(() => {
      timer.stopTimer();
    });

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const startChat = () => {
      const urlPath = token ? `?token=${token}` : `?survey=${surveyId}`;
      //    const urlPath = token ? `?token=${token}` +  `?survey=${surveyId}` : `?survey=${surveyId}`;

      socketService.connect(urlPath, () => {
        if (socketService.isConnected()) {
          startUserCamera();
          isAiActive.value = true;
          isSurveySessionActive.value = true;
          isLoadingResponse.value = true;
          messages.value = [];
          showStartChatAlert();

          if (sessionTime !== null) {
            const [hours, minutes] = sessionTime.split(":");
            const totalMinutes =
              parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            timer.startTimer((formattedDuration: string) => {
              duration.value = formattedDuration;
            }, totalMinutes);
          } else {
            timer.startTimer((formattedDuration: string) => {
              duration.value = formattedDuration;
            });
          }

          socketService.addEventListener("message", handleMessage);
        }
      });
    };

    const handleMessage = (message: any) => {
      // message {"data":null,"error":{"status":403,"name":"ForbiddenError","message":"Forbidden","details":{}}}

      console.log("message", JSON.parse(message));
      if (JSON.parse(message).data == null) {
        timer.stopTimer();
        isLoadingResponse.value = false;
        isError.value = true;
        stopUserCamera();

        Swal.fire({
          text: "Anket başlatılamadı, geçersiz anket ya da token limitiniz dolmuş olabilir. lütfen Yönetici ile iletişime geçin.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => router.push({ name: "doPoll" }));
      } else {
        let res: ChatSectionResponseDto = JSON.parse(message);
        processResponse(res);
      }
    };

    const processResponse = (res: ChatSectionResponseDto) => {
      const userDeviceData = getUserDeviceInfo();
      socketService.sendMessage(userDeviceData);

      if (res.data.attributes.productSessionMessages.data.length > 0) {
        const newMessages = res.data.attributes.productSessionMessages.data.map(
          (message: any, index: number) => {
            const isLastMessage =
              index ===
              res.data.attributes.productSessionMessages.data.length - 1;
            return {
              owner: message.attributes.owner,
              text:
                isLastMessage && message.attributes.owner == "AI"
                  ? ""
                  : message.attributes.text,
              createdAt: new Date(
                message.attributes.createdAt
              ).toLocaleTimeString("tr-TR", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            };
          }
        );

        messages.value = newMessages;
        ScrollComponent.updateAll();

        if (res.data.attributes.voice.data) {
          let url =
            environment.baseUploadUrl +
            res.data.attributes.voice.data.attributes.url;
          startAIVoice(url).then(() => {
            const lastMessage =
              res.data.attributes.productSessionMessages.data[
                res.data.attributes.productSessionMessages.data.length - 1
              ];
            if (lastMessage.attributes.owner === "AI") {
              MessageFlow(
                lastMessage.attributes.text,
                Number(AiSpeakSecond.value),
                messages.value,
                messages.value.length - 1
              );
              ScrollComponent.updateAll();
            }
          });
        }
        completionPercentage.value = res.data.attributes.completionPercentage;
      }

      if (res.data.attributes.endDate) {
        Swal.fire({
          title: "Tebrikler!",
          text: "Görüşmeyi başarıyla tamamladınız...",
          icon: "success",
        }).then(() => {
          timer.stopTimer();
          localStorage.clear();
          window.location.reload();
        });
      }
    };

    const showStartChatAlert = () => {
      Swal.fire({
        text: "Görüşme başladı.",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => router.push({ name: "doPoll" }));
    };

    const startRecording = async () => {
      video = document.getElementById("userCamera");
      if (video.srcObject) {
        mediaRecorderService.startVideoRecording(video);
      }
      isRecording.value = true;
      stopAIVoice();

      mediaRecorderService.startAudioRecording();
    };

    const startUserCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.push({ name: "doPoll" });
        });
      }
    };

    const stopUserCamera = async () => {
      if (videoElement.value) {
        videoElement.value.style.display = "none";
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      if (
        mediaRecorderService.videoRecorder &&
        mediaRecorderService.videoRecorder.state !== "inactive" &&
        mediaRecorderService.audioRecorder &&
        mediaRecorderService.audioRecorder.state !== "inactive"
      ) {
        mediaRecorderService.stopVideoRecording();
        mediaRecorderService.stopAudioRecording();
        if (mediaRecorderService.audioRecorder)
          mediaRecorderService.audioRecorder.onstop = () => {
            console.log(mediaRecorderService.videoChunks);
            const audioBlob = new Blob(mediaRecorderService.audioChunks, {
              type: "audio/mp3",
            });
            const videoBlob = new Blob(mediaRecorderService.videoChunks, {
              type: "video/mp4",
            });
            sendBlobToAPI(audioBlob, videoBlob);
            mediaRecorderService.audioChunks = [];
            mediaRecorderService.videoChunks = [];
          };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob, videoBlob: Blob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration);
        if (event.target.duration < 1) {
          isLoadingResponse.value = false;
          return Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            router.push({ name: "doPoll" });
          });
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });

          const soundUploadModel = new FileUploadModel(soundFile);

          isLoadingResponse.value = true;

          fileController
            .fileUpload(soundUploadModel)
            .then((response) => {
              if (response.isSuccess) {
                let res = response.getValue();
                let soundId = res.id;

                const data = {
                  voice: soundId,
                };

                socketService.sendMessage(JSON.stringify(data));
                sendVideoToAPI(soundId, videoBlob);
              } else {
                console.log("error", response.error.cause.cause);
                return Swal.fire({
                  text: "Lütfen tekrar deneyiniz.",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  router.push({ name: "doPoll" });
                });
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      }
    };

    const sendVideoToAPI = (soundId: string, videoBlob: Blob) => {
      const videoFile = new File([videoBlob], "userVideo.mp4", {
        type: "video/mp4",
      });

      const videoUploadModel = new FileUploadModel(videoFile);

      console.log("videoUploadModel", videoUploadModel);
t
      fileController
        .fileUpload(videoUploadModel)
        .then((response) => {
          if (response.isSuccess) {
            let res = response.getValue();
            let videoId = res.id;

            const data = {
              voice: soundId,
              video: videoId,
            };

            socketService.sendMessage(JSON.stringify(data));
          } else {
            console.log("error", response.error.cause.cause);
            return Swal.fire({
              text: "Video gönderilemedi, lütfen tekrar deneyiniz.",
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Tamam",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "doPoll" });
            });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Görüşmeyi sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
          }).then(() => {
            const data = {
              closeSession: true,
            };

            socketService.sendMessage(JSON.stringify(data));
            isAiActive.value = false;
            timer.stopTimer();
            localStorage.clear();
            window.location.reload();
          });
        }

        router.push({ name: "doPoll" });
      });
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AiSpeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    onMounted(() => {
      token = route.query.token?.toString();
      ScrollComponent.bootstrap();

      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isError,
      isLoadingResponse,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      duration,
      messages,
      messagesRef,
      isSurveySessionActive,
    };
  },
});
</script>
